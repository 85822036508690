import React from 'react';
//import "src/WhatssAppIcon.css";
import "../styles/WhatssAppIcon.css"

const WhatsAppIcon = () => {
  return (
    <div className="whatsapp-icon">
      <a 
        href="https://wa.me/527711446152" 
        target="_blank" 
        rel="noopener noreferrer"
        aria-label="Chat with us on WhatsApp"
      >
        
      </a>
    </div>
  );
};

export default WhatsAppIcon;

